.ConsignerDropOffPage {
  @media (max-width: 768px) {
    margin-bottom: 90px;
  }
}
.ConsignerDropOffPage .printable {
  display: none;
}

@media print {
  @page {
    size: landscape;
  }
  * {
    visibility: hidden;
  }

  .ConsignerDropOffPage .printable {
    display: block;
  }

  .ConsignerDropOffPage .printable .ant-table-content,
  .ConsignerDropOffPage .printable .ant-table-content * {
    visibility: visible;
  }
}